@font-face {
  font-family: "body-font";
  src: url("./assets/fonts/SFProDisplay-Regular.ttf") format("truetype");
}

* {
  font-family: "body-font";
}

body {
  background-color: #f5f8fa !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='250' height='30' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%23ffffff' stroke-width='10' %3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E") !important;
}

hr {
  border: 1px solid #ffffff !important;
  border-radius: 0.5px !important;
  opacity: 1 !important;
}

.text {
  line-height: 2rem !important;
}

.offset {
  padding-top: 56px;
  min-height: 100vh;
}

.header-home,
.header-nosotros,
.header-doctrina,
.header-ministerios,
.header-pastor,
.header-contactanos {
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  display: flex;
}

.header-home {
  background-image: url("./assets/images/bg-home.jpg");
  min-height: calc(100vh - 56px);
}

.header-nosotros,
.header-doctrina,
.header-ministerios,
.header-pastor,
.header-contactanos {
  padding: 200px 0px;
  margin-bottom: 40px;
}

.header-nosotros {
  background-image: url("./assets/images/bg-nosotros.jpg");
}

.header-doctrina {
  background-image: url("./assets/images/bg-doctrina.jpg");
}

.header-ministerios {
  background-image: url("./assets/images/bg-ministerios.jpg");
}

.header-pastor {
  background-image: url("./assets/images/bg-pastor.jpg");
}

.header-contactanos {
  background-image: url("./assets/images/bg-contactanos.jpg");
}

a {
  color: #0063b6 !important;
  text-decoration: none !important;
}

a:focus,
a:hover {
  text-decoration: underline !important;
}

.navbar-item,
.navbar-brand,
.dropdown-toggle,
.dropdown-item {
  color: #000000 !important;
  text-decoration: none !important;
}

.navbar-item:focus,
.navbar-item:hover,
.dropdown-toggle:focus,
.dropdown-toggle:hover,
.dropdown-item:focus,
.dropdown-item:hover {
  color: #0063b6 !important;
  text-decoration: none !important;
}

.navbar-brand:focus,
.navbar-brand:hover {
  color: #000000 !important;
  text-decoration: none !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.cards-home {
  color: #000000 !important;
  text-decoration: none !important;
}

.cards-home:focus,
.cards-home:hover {
  color: #0063b6 !important;
  text-decoration: none !important;
}

* {
  font-size: 18px;
}

th {
  font-weight: normal;
}

.capitalize-first {
  text-transform: lowercase;
}
.capitalize-first::first-letter {
  text-transform: uppercase;
}

.font-weight-bold {
  font-weight: bold !important;
}

.member-profile {
  width: 100%;
}

.num-col-width {
  width: 50px !important;
}