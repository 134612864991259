$theme-colors: (
  "primary-900": #001421,
  "primary-800": #002741,
  "primary-700": #003b62,
  "primary-600": #004e83,
  "primary": #0063b6,
  "primary-400": #008ce9,
  "primary-300": #2facff,
  "primary-200": #74c8ff,
  "primary-100": #bae3ff,
  "gray-900": #14171c,
  "gray-800": #282e38,
  "gray-700": #3c4653,
  "gray-600": #505d6f,
  "gray-500": #64748b,
  "gray-400": #8390a2,
  "gray-300": #a2acb9,
  "gray-200": #c1c7d1,
  "gray-100": #e0e3e8,
  "danger":  #d60000,
);

$container-max-widths: (
  sm: 600px,
  md: 700px,
  lg: 800px,
  xl: 900px,
  xxl: 1000px,
);

@import "../../node_modules/bootstrap/scss/bootstrap";
